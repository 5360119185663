.promotions-search-list span {
    display: block;
    padding: 5px;
    padding: 5px;
    cursor: default;
}

.promotions-search-list span:hover {
    background: #ff4443;
    color: white;
}

.promotions-search-list span:not(.promotions-search-list span:last-child) {
    border-bottom: 1px solid #8080805c;
}

.promotions-search-list {
    width: 343px;
    position: absolute;
    background: white;
    z-index: 10;
    border: 1px solid #8080805c;
    max-height: 230px;
    overflow: auto;
}

.input-search-user {
    position: relative;
}

.remove-user-search {
    position: absolute;
    right: 0;
    bottom: 5px;
    cursor: pointer;
}

.remove-user-search:hover {
    opacity: 0.8;
}

.create-promotion-button {
    display: flex;
    align-items: flex-end;
}

.selected-user {
    opacity: 0.5;
    pointer-events: none;
}