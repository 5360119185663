.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #ff4443;
  border-radius: 50%;
  /* width: 38px; */
  /* height: 38px; */
  animation: spin 2s linear infinite;

  /* position: absolute; */
  z-index: 1;
  /* background: #fff; */

  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  background: transparent;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
