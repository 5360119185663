@media (max-width: 1180px) {
  .verificationPopupResponsive {
    right: 8rem !important;
    width: calc(100% - 180px) !important;
  }
}

@media (max-width: 960px) {
  .verificationPopupResponsive {
    right: 14rem !important;
    width: calc(100% - 270px) !important;
  }
}

@media (max-width: 930px) {
  .verificationPopupResponsive {
    right: 8rem !important;
    width: calc(100% - 180px) !important;
  }
}

@media (max-width: 730px) {
  .verificationPopupResponsive {
    right: -1rem !important;
    width: 100% !important;
    flex-direction: column;
  }
}

.sort {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: gray;
  cursor: pointer;
}
